import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from "@fullcalendar/react";
// import 'bootstrap/dist/css/bootstrap.css';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import CreateDeliveries from "./CreateDeliveries";
import {useEffect, useState} from "react";
import Table from 'react-bootstrap/Table';
import {useFormik} from "formik";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
const DEBUG = false;
const NO_CLICKS = false;
const CLICKED_ONCE = true;
// const START_DATE_KEY = 'START_DATE';
// const END_DATE_KEY = 'END_DATE';
// const START_DATE = '2024-11-01'; // todo make dynamic
// const END_DATE = '2024-11-28'; // todo make dynamic

const Home = ({ calendarEvents, isLoading }) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [clickState, setClickState] = useState(NO_CLICKS);

    const navigate = useNavigate();

    DEBUG && console.log(calendarEvents);

    const formikCreate = useFormik({
        initialValues: {
            bookingStartDate: '',
        },
        // validationSchema: Yup.object({
        //     bookingStartDate: Yup.string().required('* Required'),
        // }),

        onSubmit: (values, { setFormSubmitted, resetForm }) => {
            CreateDeliveries(startDate, endDate).then(() => {
                // setFormSubmitted(true);
                // calendarEvents.push();
                resetForm();
                setEndDate(null);
                setStartDate(null);
                navigate(0);
            }).catch((error) => {
                console.log('Error creating booking:', error);
            });
        },
    });

    const handleDateClick = (arg) => {
        DEBUG && console.log(arg);

        if (clickState) {
            let end = arg.dateStr;
            DEBUG && console.log(`end date: ${end}`);
            setEndDate(end);
            setClickState(NO_CLICKS);
        } else {
            let start = arg.dateStr;
            DEBUG && console.log(`start date: ${start}`);
            setStartDate(start);
            // localStorage.setItem(START_DATE_KEY, start);
            setClickState(CLICKED_ONCE);
        }
    }

    const formatDate = (dateString) => {
        const options = { month: "short", day: "numeric" }
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    return (
        <>
            { (startDate || endDate) && (

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{formatDate(startDate)}</td>
                        <td>{formatDate(endDate)}</td>
                    </tr>
                    </tbody>
                </Table>
                )
            }

            { startDate && endDate && (
                <div id="form-flex-container" className="round-form">
                    <div id="form-container">
                        <form onSubmit={formikCreate.handleSubmit}>
                            <button type="submit">Create booking</button>
                        </form>
                    </div>
                </div>
            )}

            <div className="flex-center">
                <FullCalendar
                    plugins={[ dayGridPlugin, bootstrapPlugin, interactionPlugin ]}
                    events={calendarEvents}
                    initialView="dayGridMonth"
                    contentHeight={400}
                    selectable={true}
                    dateClick={handleDateClick}
                />
            </div>
        </>
    );
};

export default Home;